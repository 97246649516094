$basePath: "";
@import "./glyphicons.css";

body {
  font-family: effra, tahoma, arial, sans-serif;
  font-size: 0.9em;
  text-align: center;
  background-color: #eeeeee;
}

input,
select,
textarea {
  font-family: effra, tahoma, arial, sans-serif;
  font-size: 1em;
}

div.main {
  background: white url("@imgs/ordersystem-header.png") no-repeat;
  width: 833px;
  text-align: left;
  margin: 8px auto;
  border: 1px solid #dddddd;
  padding: 89px 20px 20px 20px;
}

h2 {
  font-size: 1.2em;
}

a:link,
a:visited {
  color: #00a200;
  text-decoration: underline;
}
a:hover,
a:active {
  color: #f00;
  text-decoration: underline;
}

.noteinline {
  font-weight: normal;
  color: #555;
}

.note {
  font-weight: normal;
  font-size: 0.85em;
  color: #555;
}
.note strong {
  color: #333;
  font-size: 1.1em;
}

span.code {
  font-family:
    Courier New,
    monospace;
}

table.steps {
  width: 100%;
}

table.steps td {
  font-weight: 400;
  font-size: 9pt;
  color: #ffffff;
  background: #333333;
  border-top: #000000 1px solid;
  border-right: #000000 1px solid;
  border-left: #555555 1px solid;
  border-bottom: none;
}

table.steps td a {
  color: #ffffff;
  text-decoration: none;
}

table.steps td.current a {
  color: #ffffff;
  text-decoration: none;
}

table.steps td.current {
  font-weight: bold;
  color: #333333;
  background-color: #6abd53;
  border-bottom: #6abd53 1px solid;
  border-left: none;
}

table.steps td.bottom {
  background-color: #6abd53;
  padding: 1px;
  border-right: 1px solid #6abd53;
  border-left: 0 solid #6abd53;
  border-bottom: 1px solid #333333;
}

table.options td.indented {
  padding-left: 40px;
}

table.options input[type="radio"] {
  margin: 0 8px;
}

table ul {
  margin-top: 0.4em;
}

table {
  border-collapse: collapse;
}

th {
  text-align: left;
}

th,
td {
  border: 1px solid #099109;
  padding: 10px;
}

form {
  padding: 0px;
  background: none;
  border: none;
}

table tr th {
  background-color: #eeeeee;
}

table thead tr th {
  background-color: #333333;
  color: white;
  font-weight: 400;
  text-align: center;
  vertical-align: top;
}

table tr.even,
table tr.even th {
  background-color: white;
}

table tr.odd,
table tr.odd th {
}

form table thead tr th {
  text-align: left;
}

form table tr th {
  vertical-align: top;
}

table tr td.number,
table tr th.number {
  text-align: right;
}

div.form_area {
  padding: 10px;
  background: #eeeeee;
  border: NONE;
}

div.form_area td,
div.form_area th {
  border: none;
}

div.form_area h3 {
  background: #dddddd;
  padding: 3px;
}

div.errors,
div.warnings {
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 10px;
  font-size: 1.1em;
}

div.errors {
  background: #ffcac1;
  border: 1px solid #b01c07;
  color: #b01c07;
}

div.warnings {
  background: #ffffce;
  border: 1px solid #b38f00;
  color: #b38f00;
}

div.uploadNotice {
  font-weight: normal;
}
div.uploadNotice.green {
  color: #099109;
}

input.edit {
  font-size: 0.8em;
}

input.num {
  text-align: right;
}

input.submit,
input.return {
  margin-top: 10px;
  border: 1px solid #a3b8be;
  font-weight: 400;
  font-size: 15px;
  min-width: 100px;
  line-height: 30px;
  background: #333333;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

table.border td,
table.border th {
  border: 1px solid #000000;
}

option.selected {
  background-color: #b7d0e7;
}

tr.unpaid {
  background-color: #fee07a;
}

tr.paid {
  background-color: #d9ffd9;
}

tr.cancelled {
  background-color: #ffddd9;
}

tr.refunded {
  background-color: #dddddd;
}

input.text,
textarea {
  width: 100%;
}

p.indent {
  margin-left: 25px;
}

a.cancel {
  color: #555555;
  margin-left: 20px;
}

.option-category {
  margin: 1em 0;
}
.option-category fieldset {
  margin: 1em 0;
}

.message {
  padding: 15px 22px;
  margin-bottom: 10px;
  background: #fdfdfd;
  border-radius: 3px;
  border: 1px solid #00a200;
  box-shadow: inset 0px 0px 5px #ccc;
}

.checkbox-container {
  position: absolute;
  background: #8dbe3e;
  display: block;
  left: 0;
  top: 0;
  width: 40px;
  height: 100%;
  line-height: 100%;
  cursor: pointer;
}

.checkbox-container .glyphicons-icon {
  position: relative;
  top: -6px;
  left: 2px;
}

table.optional {
  position: relative;
}
table.optional thead tr th {
  padding-left: 50px;
}
div.changeset-group {
  margin-bottom: 20px;
}

dl.license dt {
  font-style: none;
  font-weight: 600;
}
dl.license dd {
  margin-left: 20px;
  font-weight: 400;
}
div.license-details {
  margin-bottom: 20px;
  padding-left: 10px;
  border: 1px solid #919c97;
  font-size: 0.85em;
}

div.license-details h2 {
  padding-left: 10px;
  font-weight: 300;
  border-bottom: 1px solid #919c97;
  background-color: #dddddd;
  margin-left: -10px;
  margin-top: 0px;
  padding-bottom: 1px;
}

.product-input {
  text-align: right;
}

label.header {
  font-size: 1.5em;
  line-height: 2em;
  font-weight: 600;
}

label.header input {
  display: inline-block;
  float: left;
  margin-top: 1em;
  margin-right: 10px;
}

span.notice-me {
  color: #c64072;
  font-weight: 600;
}

.email {
  text-decoration: underline;
}

.ui.dropdown label.secondary {
  color: #aaa;
  font-size: 0.9rem;
}

#sub-reseller-details th {
  /* avoid jump when sub-sub loads in */
  min-width: 120px;
  white-space: nowrap;
  vertical-align: middle;
}

#sub-reseller-details .search-container {
  width: 100%;
}
